import { createAction, props } from '@ngrx/store';

import { IMainmenuNavigation } from '../navigation.interface';

export const getNavigation = createAction('[Navigation] Get Navigation');

export const fetchNavigation = createAction('[Navigation] Fetch Navigation');

export const fetchNavigationSuccess = createAction(
    '[Navigation] Get Navigation Success',
    props<{ navigation: IMainmenuNavigation }>()
);

export const fetchNavigationFailure = createAction(
    '[Navigation] Get Navigation Failure',
    props<{ error: string }>()
);
