import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap, take } from 'rxjs';

import * as NavigationActions from './navigation.actions';
import { AppStateInterface } from '../navigation.interface';
import { AppNavigationService } from '../navigation.service';
import { initiallyLoadedSelector } from './navigation.selectors';

@Injectable()
export class AppNavigationEffects {
    fetchNavigation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NavigationActions.fetchNavigation),

            switchMap(() => {
                return this.dynamicNavigationService.getNavigation().pipe(
                    take(1),
                    map((navigation) => NavigationActions.fetchNavigationSuccess({ navigation })),
                    catchError((error) =>
                        of(
                            NavigationActions.fetchNavigationFailure({
                                error: error.message
                            })
                        )
                    )
                );
            })
        )
    );

    getNavigation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NavigationActions.getNavigation),
            switchMap(() => this.store.select(initiallyLoadedSelector).pipe(take(1))),
            filter((initiallyLoaded) => !initiallyLoaded),
            switchMap(() => of(NavigationActions.fetchNavigation()))
        )
    );

    constructor(
        private actions$: Actions,
        private dynamicNavigationService: AppNavigationService,
        private store: Store<AppStateInterface>
    ) {}
}
