<ui-icon-group-list *ngIf="items$ | async as data" size="small">
    <ui-icon-group>
        <a
            *ngFor="let category of data['categories']"
            ui-icon
            direction="column"
            [routerLink]="['/hitta', 'typ', 'vin', category._id | customSlug]"
        >
            <!-- Ta bort transformIcon när firebase icons är uppdaterade -->
            <mat-icon [svgIcon]="category.iconv2"></mat-icon>

            <ui-label color="primary" text-style="none">{{
                category.title === 'Mousserande vin'
                    ? 'Bubbel'
                    : category.title.replace('vin', '').trim()
            }}</ui-label>
        </a>
    </ui-icon-group>

    <!-- <div class="app-navigation-shortcuts-divider"></div> -->

    <ui-icon-group size="small">
        <a
            *ngFor="let country of data['countries']"
            ui-icon
            direction="column"
            [routerLink]="['/hitta', 'land', country._id | customSlug]"
            [queryParams]="{ 'category:level1': 'vin' }"
        >
            <ui-flag-icon country="{{ country.iconColor }}"></ui-flag-icon>
            <ui-label color="primary" text-style="none">{{ country.title }}</ui-label>
        </a>
    </ui-icon-group>

    <!-- <div class="app-navigation-shortcuts-divider"></div> -->

    <ui-icon-group size="small">
        <a
            *ngFor="let grape of data['grapes']"
            ui-icon
            direction="column"
            [routerLink]="['/hitta', 'druva', grape._id | customSlug]"
        >
            <mat-icon [svgIcon]="'grape'" [ngStyle]="{ color: grape.iconColor }"></mat-icon>
            <ui-label color="primary" text-style="none">{{ grape.title.split(' ')[0] }}</ui-label>
        </a>
    </ui-icon-group>
</ui-icon-group-list>
