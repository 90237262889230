import { createReducer, on } from '@ngrx/store';

import * as NavigationActions from './navigation.actions';
import { IDynamicNavState } from '../navigation.interface';

export const initialState: IDynamicNavState = {
    isLoading: false,
    initiallyLoaded: false,
    error: null,
    content: {}
};

export const reducers = createReducer(
    initialState,
    on(NavigationActions.getNavigation, (state) => ({
        ...state
    })),
    on(NavigationActions.fetchNavigation, (state) => ({
        ...state,
        isLoading: true,
        initiallyLoaded: true,
        error: null,
        content: {}
    })),
    on(NavigationActions.fetchNavigationSuccess, (state, action) => ({
        ...state,
        isLoading: false,
        initiallyLoaded: true,
        error: null,
        content: action.navigation
    })),
    on(NavigationActions.fetchNavigationFailure, (state, action) => ({
        ...state,
        isLoading: false,
        error: action.error
    }))
);
