import { collection, CollectionReference, Firestore } from '@angular/fire/firestore';

import { FirebaseCollections } from '@vb/data-access/angular/firestore/firestore-config.constant';
import { serializeFirestoreDocument } from '@vb/data-access/angular/firestore/utils/serialize-document.helper';

import { IMainmenuNavigation } from './navigation.interface';

export const navigationCollection = (firestore: Firestore) => {
    return (
        collection(
            firestore,
            FirebaseCollections.NAVIGATION
        ) as CollectionReference<IMainmenuNavigation>
    ).withConverter<IMainmenuNavigation>({
        fromFirestore: (snapshot) => {
            const product = snapshot.data();

            return serializeFirestoreDocument<IMainmenuNavigation>(product);
        },
        toFirestore: (model) => model
    });
};
