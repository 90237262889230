import { Injectable } from '@angular/core';
import { doc, Firestore, getDoc } from '@angular/fire/firestore';

import { from, map, Observable, take } from 'rxjs';

import { navigationCollection } from './navigation.collection';
import { IMainmenuNavigation } from './navigation.interface';

@Injectable()
export class AppNavigationService {
    constructor(private firestore: Firestore) {}

    getNavigation(): Observable<IMainmenuNavigation> {
        const collectionRef = navigationCollection(this.firestore);

        return from(getDoc(doc(collectionRef, 'mainmenu'))).pipe(
            take(1),
            map((snap) => snap.data())
        );
    }
}
