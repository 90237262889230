import { IMainmenuItem, IMainmenuTab } from './mainmenu.interface';

export const MAINMENU_TABS: IMainmenuTab[] = [
    {
        slug: 'vin',
        displayName: 'Vin',
        icon: 'wine-mixed'
    },
    {
        slug: 'sprit',
        displayName: 'Sprit',
        icon: 'spirit-whisky'
    },
    {
        slug: 'ol',
        displayName: 'Öl',
        icon: 'beer-light'
    },
    {
        slug: 'cider-blanddrycker',
        displayName: 'Cider',
        icon: 'cider-cider'
    }
];

export const MAINMENU_ITEMS: IMainmenuItem[] = [
    {
        parent_id: 'category:level1:vin',
        id: 'category:level2',
        parent: 'vin',
        slug: 'typ',
        includeParentAsParam: true,
        showAllLabel: 'Visa alla viner',
        diplayName: 'Typ',
        items: []
    },
    {
        parent_id: 'category:level1:vin',
        id: 'grape',
        parent: 'vin',
        slug: 'druva',
        diplayName: 'Druva',
        items: []
    },
    {
        parent_id: 'category:level1:vin',
        id: 'origin:country',
        parent: 'vin',
        slug: 'land',
        includeParentAsQuery: true,
        diplayName: 'Land',
        items: []
    },
    {
        parent_id: 'category:level1:ol',
        id: 'category:level2',
        parent: 'ol',
        slug: 'typ',
        includeParentAsParam: true,
        diplayName: 'Typ',
        showAllLabel: 'Visa allt öl',
        items: []
    },
    {
        parent_id: 'category:level1:sprit',
        id: 'category:level2',
        parent: 'sprit',
        slug: 'typ',
        asQuery: false,
        includeParentAsParam: true,
        showAllLabel: 'Visa all sprit',
        diplayName: 'Typ',
        items: []
    },
    {
        parent_id: 'category:level1:ol',
        id: 'origin:country',
        parent: 'ol',
        slug: 'land',
        asQuery: true,
        includeParentAsParam: true,
        diplayName: 'Land',
        items: []
    },
    {
        parent_id: 'category:level1:cider-blanddrycker',
        id: 'category:level2',
        parent: 'cider-blanddrycker',
        slug: 'typ',
        asQuery: false,
        includeParentAsParam: true,
        showAllLabel: 'Visa all cider',
        diplayName: 'Typ',
        items: []
    },
    {
        parent_id: 'category:level1:cider-blanddrycker',
        id: 'origin:country',
        parent: 'cider-blanddrycker',
        slug: 'land',
        includeParentAsQuery: true,
        diplayName: 'Land',
        items: []
    }
];
