import { animate, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

import {
    EASING_ENTERING,
    EASING_LEAVING,
    fadeInOut,
    VELOCITY_ENTERING,
    VELOCITY_LEAVING
} from '@vb/common/animations';

import * as NavigationActions from '../store/navigation.actions';
import { AppStateInterface } from '../navigation.interface';
import { navigationSelector } from '../store/navigation.selectors';
import { MAINMENU_ITEMS, MAINMENU_TABS } from './mainmenu.config';
import { IMainmenuTab } from './mainmenu.interface';

@Component({
    selector: 'app-mainmenu',
    templateUrl: './mainmenu.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: [
        fadeInOut,
        trigger('menuAnimation', [
            transition('void => large', [
                style({ opacity: 0, transform: 'translateY(-30%)' }),
                animate(
                    `${VELOCITY_ENTERING} ${EASING_ENTERING}`,
                    style({ opacity: 1, transform: 'translateY(0)' })
                )
            ]),
            transition('large => void', [
                style({ opacity: 1, transform: 'translateY(0)' }),
                animate(
                    `${VELOCITY_LEAVING} ${EASING_LEAVING}`,
                    style({ opacity: 0, transform: 'translateY(-30%)' })
                )
            ]),

            transition('void => small', [
                style({ opacity: 0, transform: 'translateX(-30%)' }),
                animate(
                    `${VELOCITY_ENTERING} ${EASING_ENTERING}`,
                    style({ opacity: 1, transform: 'translateX(0)' })
                )
            ]),
            transition('small => void', [
                style({ opacity: 1, transform: 'translateX(0)' }),
                animate(
                    `${VELOCITY_LEAVING} ${EASING_LEAVING}`,
                    style({ opacity: 0, transform: 'translateX(-30%)' })
                )
            ])
        ])
    ]
})
export class AppMainmenuComponent implements OnInit, OnDestroy {
    private _destroy$ = new Subject<void>();

    open$ = new BehaviorSubject<boolean>(false);

    tabs = [...MAINMENU_TABS];
    selectedTab$ = new BehaviorSubject<IMainmenuTab>(this.tabs[0]);

    isSmallScreen = this._breakpointObserver.isMatched([Breakpoints.Small, Breakpoints.XSmall]);

    lists$ = this._store.select(navigationSelector).pipe(
        filter((nav) => !!nav),
        map((nav) => {
            return MAINMENU_ITEMS.map((list) => {
                const dynamicParent = nav[list.parent_id];
                if (!dynamicParent) {
                    return undefined;
                }

                const dynamicItems = dynamicParent[list.id];
                if (!dynamicItems) {
                    return undefined;
                }

                return {
                    ...list,
                    items: dynamicItems.map((item) => {
                        return {
                            slug: item?._id.replace(list.id + ':', ''),
                            displayName: item?.title,
                            icon: item?.icon,
                            iconv2: item?.iconv2,
                            iconColor: item?.iconColor
                        };
                    })
                };
            })
                .filter((list) => !!list)
                .map((list) => this.buildList(list));
        })
    );

    constructor(
        private _router: Router,
        private _breakpointObserver: BreakpointObserver,
        private _store: Store<AppStateInterface>,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnInit() {
        this._store.dispatch(NavigationActions.getNavigation());

        this._router.events
            .pipe(
                filter((e) => e instanceof NavigationStart),
                takeUntil(this._destroy$)
            )
            .subscribe(() => {
                this.close();
            });

        this.open$.pipe(takeUntil(this._destroy$)).subscribe({
            next: (status) => {
                status
                    ? this.document.body.classList.add('megamenu-open')
                    : this.document.body.classList.remove('megamenu-open');
            }
        });
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    buildList(list) {
        const routerLink = ['hitta'];
        const queryParams = {};

        if (!list.asQuery) {
            routerLink.push(list.slug);
        } else {
            routerLink.push('typ');
        }

        if (list.includeParentAsParam) {
            routerLink.push(list.parent);
        }
        if (list.showAllLabel) {
            list.showAllRouterLink = [...routerLink];
        }
        if (list.includeParentAsQuery) {
            queryParams['category:level1'] = list.parent;
        }

        list.items = list.items.map((item) => {
            const itemQueryParams = { ...queryParams };

            if (list.asQuery) {
                itemQueryParams['origin:country'] = item.slug;
            }

            item.routerLink = routerLink.concat(!list.asQuery ? [item.slug] : []);

            item.queryParams = itemQueryParams;

            return item;
        });

        return list;
    }

    toggle() {
        this.open$.pipe(take(1)).subscribe({
            next: (isOpen) => {
                isOpen ? this.close() : this.open();
            }
        });
    }

    open() {
        this.open$.next(true);
    }

    close() {
        this.open$.next(false);
    }

    selectTab($event: MouseEvent, tab: IMainmenuTab) {
        $event.preventDefault();

        this.selectedTab$.next(tab);
    }
}
