<div *ngIf="open$ | async" class="app-header-megamenu-overlay" (click)="close()" @fadeInOut></div>

<div
    class="app-header-megamenu"
    *ngIf="open$ | async"
    [@menuAnimation]="isSmallScreen ? 'small' : 'large'"
>
    <mat-toolbar class="app-header-megamenu__mobile-title">
        <button mat-icon-button (click)="close()">
            <mat-icon [svgIcon]="'close'"></mat-icon>
        </button>
    </mat-toolbar>
    <div class="app-container">
        <div class="app-megamenu" *ngIf="selectedTab$ | async; let selectedTab">
            <div class="app-megamenu-item --tabs">
                <label class="label caption">Sortiment</label>
                <mat-nav-list>
                    <a
                        mat-list-item
                        *ngFor="let tab of tabs"
                        [activated]="selectedTab.slug === tab.slug ? true : null"
                        (click)="selectTab($event, tab)"
                    >
                        <mat-icon matListItemIcon [svgIcon]="tab.icon"></mat-icon>
                        <span matListItemTitle class="mat-list-item__label">{{
                            tab.displayName
                        }}</span>
                    </a>
                </mat-nav-list>
            </div>

            <div
                class="app-megamenu-item"
                *ngFor="let list of lists$ | async"
                [class.--selected]="selectedTab.slug === list.parent"
            >
                <label class="label caption">{{ list.diplayName }}</label>

                <mat-nav-list dense>
                    <a
                        *ngFor="let item of list.items"
                        mat-list-item
                        [routerLink]="item.routerLink"
                        [queryParams]="item.queryParams"
                    >
                        <ui-icon matListItemIcon size="x-small">
                            <ui-flag-icon *ngIf="item.icon === 'flag'" [country]="item.iconColor">
                            </ui-flag-icon>

                            <mat-icon
                                *ngIf="item.icon === 'grape'"
                                [svgIcon]="item.icon"
                                [ngStyle]="{ color: item.iconColor }"
                            ></mat-icon>

                            <!-- Ta bort transformIcon när firebase icons är uppdaterade -->
                            <mat-icon
                                *ngIf="item.icon !== 'grape' && item.icon !== 'flag'"
                                [svgIcon]="item.iconv2"
                            ></mat-icon>
                        </ui-icon>

                        <span
                            matListItemTitle
                            [title]="list.diplayName + ': ' + item.displayName"
                            class="text-truncate"
                        >
                            {{ item.displayName }}</span
                        >
                    </a>
                </mat-nav-list>
                <p class="app-megamenu-item__footer-link" *ngIf="list.showAllLabel">
                    <a [routerLink]="list.showAllRouterLink">{{ list.showAllLabel }}</a>
                </p>
            </div>
        </div>

        <mat-divider class="u-md-up-hide"></mat-divider>

        <div class="app-megamenu u-md-up-hide">
            <div class="app-megamenu-item --selected">
                <h2 style="padding-left: 16px">Om Oss</h2>
                <mat-nav-list dense>
                    <a mat-list-item [routerLink]="['/om-oss', 'om-vinborsen']">Om Vinbörsen</a>
                    <a mat-list-item [routerLink]="['/om-oss', 'hur-funkar-det']"
                        >Hur funkar det?</a
                    >
                    <a mat-list-item [routerLink]="['/om-oss', 'redaktionen']">Redaktionen</a>
                    <a mat-list-item [routerLink]="['/om-oss', 'privacy-policy']">Privacy Policy</a>
                </mat-nav-list>
            </div>
            <div class="app-megamenu-item --selected">
                <h2 style="padding-left: 16px">Inspiration</h2>
                <mat-nav-list dense>
                    <a mat-list-item [routerLink]="['/inspiration', 'tema']">Tema</a>
                    <a mat-list-item [routerLink]="['/inspiration', 'fredagstipset']"
                        >Fredagstipset</a
                    >
                    <a mat-list-item [routerLink]="['/inspiration', 'antligen-helg']"
                        >Äntligen helg</a
                    >
                    <a mat-list-item [routerLink]="['/inspiration', 'snurra-flaskan']"
                        >Snurra Flaskan</a
                    >
                </mat-nav-list>
            </div>
        </div>
    </div>
</div>
