import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UiIconsModule } from '@vb/ui/common/icons';
import { UiLabelModule } from '@vb/ui/common/label';

import { CustomSlugPipe } from './custom-slug.pipe';
import { AppMainmenuComponent } from './mainmenu/mainmenu.component';
import { AppNavigationService } from './navigation.service';
import { AppShortcutNavigationComponent } from './shortcuts/shortcuts.component';
import { AppNavigationEffects } from './store/navigation.effects';
import { reducers } from './store/navigation.reducers';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        MatIconModule,
        MatListModule,
        MatButtonModule,
        MatToolbarModule,

        UiIconsModule,
        UiLabelModule,

        StoreModule.forFeature('navigation', reducers),
        EffectsModule.forFeature([AppNavigationEffects])
    ],
    declarations: [AppMainmenuComponent, AppShortcutNavigationComponent, CustomSlugPipe],
    exports: [AppMainmenuComponent, AppShortcutNavigationComponent, CustomSlugPipe],
    providers: [AppNavigationService]
})
export class AppNavigationModule {}
