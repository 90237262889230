import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'customSlug'
})
export class CustomSlugPipe implements PipeTransform {
    transform(value: string): string {
        return value.split(':').pop();
    }
}
