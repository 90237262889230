import { createSelector } from '@ngrx/store';

import { AppStateInterface } from '../navigation.interface';

export const SelectFeature = (state: AppStateInterface) => state.navigation;

export const isLoadingSelector = createSelector(SelectFeature, (state) => state?.isLoading);

export const navigationSelector = createSelector(SelectFeature, (state) => state?.content);

export const errorSelector = createSelector(SelectFeature, (state) => state.error);

export const initiallyLoadedSelector = createSelector(
    SelectFeature,
    (state) => state.initiallyLoaded
);
