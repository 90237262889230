import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Store } from '@ngrx/store';
import { filter, map, Observable } from 'rxjs';

import { TYPE_ICON_MAP } from '@vb/ui/common/icons/category/ui-category-icon.config';
import { handelize } from '@vb/utilities/helpers/handelize';

import * as NavigationActions from '../store/navigation.actions';
import * as NavigationSelectors from '../store/navigation.selectors';
import { AppStateInterface } from '../navigation.interface';

@Component({
    selector: 'app-navigation-shortcuts',
    templateUrl: './shortcuts.component.html',
    styleUrls: ['./shortcuts.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'app-navigation-shortcuts'
    }
})
export class AppShortcutNavigationComponent implements OnInit {
    isLoading$: Observable<boolean> = this.store.select(NavigationSelectors.isLoadingSelector);
    error$: Observable<string | null> = this.store.select(NavigationSelectors.errorSelector);

    items$ = this.store.select(NavigationSelectors.navigationSelector).pipe(
        filter((nav) => !!nav),
        map((nav) => {
            const data = nav['category:level1:vin'];

            if (!data) {
                return undefined;
            }

            const categories = data['category:level2'].slice(0, 4);
            const grapes = data['grape'].slice(0, 4);
            const countries = data['origin:country'].slice(0, 4);

            return {
                grapes,
                countries,
                categories
            };
        })
    );

    constructor(private store: Store<AppStateInterface>) {}

    ngOnInit(): void {
        this.store.dispatch(NavigationActions.getNavigation());
    }

    titleCleaner(wineName: string) {
        return handelize(wineName);
    }

    transformIcon(icon: string) {
        return TYPE_ICON_MAP[icon];
    }
}
