import { InjectionToken } from '@angular/core';

export class AlgoliaAccessConfig {
    appId!: string;
    apiKey!: string;
}

export const ALGOLIA_ACCESS_OPTIONS = new InjectionToken<AlgoliaAccessConfig>(
    'algolia-access-options',
    {
        providedIn: 'root',
        factory: ALGOLIA_ACCESS_OPTIONS_FACTORY
    }
);

/** @docs-private */
export function ALGOLIA_ACCESS_OPTIONS_FACTORY(): AlgoliaAccessConfig {
    return new AlgoliaAccessConfig();
}
